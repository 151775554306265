@import '../variables';
@import "./inputForm_vars";

#InputFormContainer{
	width:var(--InputFormContainerWidth);
	border-radius: $borderRadius;
	margin:25px auto 0 auto;
	padding:5px 15px;
	background-color: rgba(0,0,0,.3);
	a{
		text-decoration: underline;
		margin-top: 40px;
	}
}

.inputFormInput{
	font-size: var(--inputFormInputFontSize);
	display: block;
	width:100%;
	margin:10px 0;
	text-align: center;
	padding-top:4px;
	border:none;
	border-radius: $borderRadius;
}

.inputFormInput[type=button]{
	background-color: $Color2;
	color:white;
}

.inputFormContainer{
	display:flex;
	flex-direction: row;
	overflow: hidden;
	border-radius: $borderRadius;

	.inputFormInput{
		flex:1;
		border-radius: 0;
	}

}





.inputFormInfo{
	width:55%;
	margin: 0 auto;
}

#errorMessageContainer{
	display: flex;
	flex-direction: column;
}

.errorMessage{
	border:solid rgb(255, 0, 0) 1px;
	background-color: maroon;
	flex:1;
	margin-top:5px;
	padding:1px 5px;
	text-align: center;
}
