/*Orange*/
/*Orange Light*/
/*Navy Blue */
/*Navy Blue Light*/
/*white text color*/
/*Orange*/
/*Orange Light*/
/*Navy Blue */
/*Navy Blue Light*/
/*white text color*/
@media (min-width: 1000px) {
  #InputFormContainer {
    --InputFormContainerWidth:680px;
    --inputFormInputFontSize:22px;
  }
}
@media (max-width: 999px) and (min-width: 600px) {
  #InputFormContainer {
    --InputFormContainerWidth:520px;
    --inputFormInputFontSize:20px;
  }
}
@media (max-width: 599px) {
  #InputFormContainer {
    --InputFormContainerWidth:90%;
    --inputFormInputFontSize:16px;
  }

  #DescriptionInput.inputFormInput {
    height: 75px;
  }
}
#InputFormContainer {
  width: var(--InputFormContainerWidth);
  border-radius: 4px;
  margin: 25px auto 0 auto;
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.3);
}
#InputFormContainer a {
  text-decoration: underline;
  margin-top: 40px;
}

.inputFormInput {
  font-size: var(--inputFormInputFontSize);
  display: block;
  width: 100%;
  margin: 10px 0;
  text-align: center;
  padding-top: 4px;
  border: none;
  border-radius: 4px;
}

.inputFormInput[type=button] {
  background-color: #e77828;
  color: white;
}

.inputFormContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 4px;
}
.inputFormContainer .inputFormInput {
  flex: 1;
  border-radius: 0;
}

.inputFormInfo {
  width: 55%;
  margin: 0 auto;
}

#errorMessageContainer {
  display: flex;
  flex-direction: column;
}

.errorMessage {
  border: solid red 1px;
  background-color: maroon;
  flex: 1;
  margin-top: 5px;
  padding: 1px 5px;
  text-align: center;
}