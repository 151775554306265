@media (min-width:$widthWide){
	#InputFormContainer{
		--InputFormContainerWidth:680px;
		--inputFormInputFontSize:22px;
	}
}
@media (max-width:($widthWide - 1)) and (min-width:$widthNarrow){
	#InputFormContainer{
		--InputFormContainerWidth:520px;
		--inputFormInputFontSize:20px;
	}
}
@media (max-width:($widthNarrow - 1)){
	#InputFormContainer{
		--InputFormContainerWidth:90%;
		--inputFormInputFontSize:16px;
	}
	#DescriptionInput.inputFormInput{
		height:75px;
	}
}
